import { ButtonBase, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/images/right-wakeel-logo-1.png'; 
import { APP_DEFAULT_PATH } from 'config'; 

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: object;
  to?: string;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  // Detect if the screen width is below 600px (mobile)
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
      <img
        src={logoImage}
        alt="Logo"
        style={{
          width: 'auto',
          height: isMobile ? '34px' : '50px', // Adjust height dynamically
        }}
      />
    </ButtonBase>
  );
};

export default LogoSection;
